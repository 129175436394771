// // eslint-disable-next-line promise/param-names
// export default new Promise(($export) => {
//   debugger
//   if (window.location.hostname.startsWith('app')) {
//     import('./firebase.prod.service').then(({ db, auth }) => {
//       $export({
//         db,
//         auth,
//       })
//     })
//   } else {
//     import('./firebase.dev.service').then(({ devAuth, devDb }) => {
//       $export({
//         db: devDb,
//         auth: devAuth,
//       })
//     })
//   }
// })

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import config from '../firebase.dev.config'
import { DataView } from '~/models/datagrid'

export const app =
  firebase.apps.find((app) => {
    return app.name === '[DEFAULT]'
  }) ?? firebase.initializeApp(config)

export const db = app.firestore()
export const auth = app.auth()
export const GoogleAuthProvider = new firebase.auth.GoogleAuthProvider()
export const FacebookAuthProvider = new firebase.auth.FacebookAuthProvider()

export default firebase

export { DataView }
