import * as Tone from 'tone'
import { createModule, mutation, action } from 'vuex-class-component'
import SuperpoweredModule from '~/static/superpowered.js'
import { SuperpoweredInstance, SuperpoweredNode } from '~/models/superpowered'
import { Song } from '~/store/store.vuex'

import {
  beatNumberToFramePosition,
  calculateKeyDifference,
} from '~/utils/music.utils'

import {
  MixController,
  Mode,
  State,
  SectionPlayer,
  StemAudio,
  StemPlayer,
  AudioData,
  StemKind,
  StemVolume,
  Send,
  ProcessorMessage,
  PadPlayer,
  MixerMode,
} from '~/services/mix.interface'

import { mapObject } from '~/utils/functional.utils'

interface Processor {
  name: string
  objectURL: string
}

const VuexModule = createModule({
  namespaced: 'mix',
  strict: false,
  target: 'nuxt',
  enableLocalWatchers: true,
})

export class MixEngine extends VuexModule implements MixController {
  audioContext: AudioContext | null = null
  audioCache: Cache | null = null
  processor: Processor | null = null
  Superpowered: SuperpoweredInstance | null = null
  maxPlayers = 16
  maxSections = 32
  currentSectionIndices: number[] = []
  state = new State()
  mode = MixerMode.interactive
  bpm = 60
  key = 'C'
  transportState: Tone.PlaybackState = Tone.Transport.state
  songTransportTime = 0
  currentBeat = 1
  currentSixteenths = 1
  stemPlayers: StemPlayer[] = []
  padPlayers: PadPlayer[][] = [[]]
  currentPlayers: SectionPlayer[] = []
  currentPadPlayer: PadPlayer | null = null
  mashupSequencePadPlayers: PadPlayer[] = []
  message = ''

  get isPlaying() {
    return this.transportState === 'started'
  }

  get isPaused() {
    return this.transportState === 'paused'
  }

  get isStopped() {
    return this.transportState === 'stopped'
  }

  $subscribe = {
    bpm(newValue: number) {
      Tone.Transport.bpm.value = newValue
    },
  }

  @action async initialize(
    processorPath: string = '/processors/stem.processor.js'
  ): Promise<void> {
    // Destruction
    this.audioContext?.close()
    this.Superpowered = await MixEngine.initializeSuperpowered()
    this.audioCache = await MixEngine.initializeAudioCache()
    this.processor = await MixEngine.createProcessor(processorPath)
    this.audioContext = this.Superpowered.getAudioContext(44100)
    await Tone.start()
    Tone.Transport.on('start', () => {
      this.transportState = Tone.Transport.state
    })
    Tone.Transport.on('pause', () => {
      this.transportState = Tone.Transport.state
    })
    Tone.Transport.on('stop', () => {
      this.transportState = Tone.Transport.state
    })
    // eslint-disable-next-line no-console
    console.log('🥁 Tone started')
    this.state.set(Mode.setup)
  }

  static async initializeSuperpowered(): Promise<SuperpoweredInstance> {
    return await new Promise((resolve) => {
      SuperpoweredModule({
        licenseKey: 'ExampleLicenseKey-WillExpire-OnNextUpdate',
        enableAudioTimeStretching: true,
        onReady(SuperpoweredInstance: SuperpoweredInstance) {
          resolve(SuperpoweredInstance)
        },
      })
    })
  }

  static async initializeAudioCache(): Promise<Cache> {
    return await window.caches.open('audio-cache')
  }

  static async createProcessor(path: string): Promise<Processor> {
    const responseProcessor = await fetch(path)
    const processorSource = await responseProcessor.text()
    const fixedProcessorSource = processorSource.replace(
      "'superpowered.js'",
      `'${window.location.origin}/superpowered.js'`
    )
    // FIXME: Return a 'text/javascript' response header from nuxt
    const processorBlob = new Blob([fixedProcessorSource], {
      type: 'text/javascript',
    })
    return {
      name: path,
      objectURL: URL.createObjectURL(processorBlob),
    }
  }

  @action async createAudioNode(
    onMessageCallback: (message: object) => void
  ): Promise<SuperpoweredNode> {
    return await this.Superpowered!.createAudioNodeAsync(
      this.audioContext!,
      this.processor!.objectURL,
      this.processor!.name,
      onMessageCallback
    )
  }

  @action async getOrFetchAudioStem({
    song,
    stemKind,
    stemURL,
  }: {
    song: Song
    stemKind: StemKind
    stemURL: string
  }): Promise<{ kind: StemKind; audio: AudioData }> {
    const leftKey = `/Float32Buffer/${song.youtubeID}/${stemKind}/left`
    const rightKey = `/Float32Buffer/${song.youtubeID}/${stemKind}/right`
    const cachedLeft = await this.audioCache!.match(leftKey)
    const cachedRight = await this.audioCache!.match(rightKey)

    if (cachedLeft && cachedRight) {
      this.message = `Retrieving ${song.title} ${stemKind} from store...`
      const promises = [cachedLeft.arrayBuffer(), cachedRight.arrayBuffer()]
      const [leftChannel, rightChannel] = await Promise.all(promises)
      return {
        kind: stemKind,
        audio: {
          left: new Float32Array(leftChannel),
          right: new Float32Array(rightChannel),
        },
      }
    } else {
      this.state.set(Mode.downloading)
      this.message = 'Downloading music...'
      const responseAudio = await fetch(stemURL)

      this.message = 'Decoding audio...'
      const rawData = await responseAudio.arrayBuffer()
      // Safari doesn't support await for decodeAudioData yet
      const pcmData: AudioBuffer = await new Promise((resolve) => {
        this.audioContext!.decodeAudioData(rawData, (pcmData) =>
          resolve(pcmData)
        )
      })
      const leftChannelData = pcmData.getChannelData(0)
      const rightChannelData = pcmData.getChannelData(1)
      const leftResponse = new Response(leftChannelData.buffer, {
        // @ts-ignore
        headers: {
          'Content-Type': 'ArrayBuffer',
          'Content-Length': leftChannelData.length,
        },
      })
      const rightResponse = new Response(rightChannelData.buffer, {
        // @ts-ignore
        headers: {
          'Content-Type': 'ArrayBuffer',
          'Content-Length': rightChannelData.length,
        },
      })
      await this.audioCache!.put(new Request(leftKey), leftResponse)
      await this.audioCache!.put(new Request(rightKey), rightResponse)
      return {
        kind: stemKind,
        audio: { left: leftChannelData, right: rightChannelData },
      }
    }
  }

  @action async createSongSectionNodes({
    song,
    playerIndex,
  }: {
    song: Song
    playerIndex: number
  }) {
    const newStemPlayer = new StemPlayer(song)
    this.stemPlayers[playerIndex] = newStemPlayer

    if (playerIndex === 0) {
      this.bpm = song.bpm
    }

    const stemPromises = mapObject(song.stemFileURLs, (stemKind, stemURL) => {
      return this.getOrFetchAudioStem({
        song,
        stemKind,
        stemURL,
      })
    })

    const stems = await Promise.all(Object.values(stemPromises))
    const audioData = {
      bass: stems.find((stem) => stem.kind === 'bass')!.audio,
      drums: stems.find((stem) => stem.kind === 'drums')!.audio,
      melody: stems.find((stem) => stem.kind === 'melody')!.audio,
      vocals: stems.find((stem) => stem.kind === 'vocals')!.audio,
    }

    for (const [sectionIndex, section] of song.sections.entries()) {
      const [startFrame, endFrame] = [
        section.beatStart,
        section.beatEnd,
      ].map((beat) =>
        beatNumberToFramePosition(
          this.audioContext!.sampleRate,
          song.bpm,
          song.msStartBeatOffset / 1000,
          beat
        )
      )
      const sectionAudio = mapObject(audioData, (_stemKind, audio) => {
        return {
          left: audio.left.slice(startFrame, endFrame),
          right: audio.right.slice(startFrame, endFrame),
        }
      })
      const stems: StemAudio = { startFrame, endFrame, ...sectionAudio }
      const sectionPlayer = new SectionPlayer(
        song,
        section,
        sectionIndex,
        stems
      )
      const audioNode = await this.createAudioNode(
        sectionPlayer.onMessageFromAudioScope.bind(sectionPlayer)
      )
      sectionPlayer.setAudioNode(audioNode)
      newStemPlayer.sections[sectionIndex] = sectionPlayer
      audioNode.connect(this.audioContext!.destination)
    }
    this.currentSectionIndices[playerIndex] = 0
    this.state.set(Mode.ready)
    this.message = 'Audio downloaded and decoded'
  }

  @mutation createPadPlayers() {
    if (this.stemPlayers.length < 2) {
      throw new Error(`A minimum of two songs are supported by PadMatrix`)
    }
    const [topPlayer, leftPlayer] = [this.stemPlayers[0], this.stemPlayers[1]]
    const [topSong, leftSong] = [topPlayer.song, leftPlayer.song]
    const [topKey, leftKey] = [topSong.key, leftSong.key]
    const keyDiff = calculateKeyDifference(topKey, leftKey)
    const timeStretchRate = topSong.bpm / leftSong.bpm
    for (const sectionPlayer of topPlayer.sections) {
      const stemVolume: StemVolume = { bass: 0, drums: 1, melody: 0, vocals: 1 }
      sectionPlayer.sendMessageToAudioScope({ stemVolume })
    }
    for (const sectionPlayer of leftPlayer.sections) {
      const stemVolume: StemVolume = { bass: 1, drums: 0, melody: 1, vocals: 0 }
      sectionPlayer.sendMessageToAudioScope({
        stemVolume,
        pitchShift: keyDiff,
        timeStretch: timeStretchRate,
      })
    }

    switch (this.stemPlayers.length) {
      case 1:
        this.padPlayers = [
          this.stemPlayers[0].sections.map(
            (topPlayer) => new PadPlayer(this, topPlayer)
          ),
        ]
        break
      case 2:
      default:
        if (this.stemPlayers.length === 0 || this.stemPlayers.length > 2) {
          // eslint-disable-next-line no-console
          console.warn(
            `${this.stemPlayers.length} Stemplayer are not yet supported in the PadMatrix`
          )
        }
        this.padPlayers = this.stemPlayers[1].sections.map((leftPlayer) =>
          this.stemPlayers[0].sections.map(
            (topPlayer) => new PadPlayer(this, topPlayer, leftPlayer)
          )
        )
    }
  }

  @mutation togglePlay({
    playerIndex,
    sectionIndex,
  }: {
    playerIndex: number
    sectionIndex: number
  }) {
    const nextPlayer = this.stemPlayers[playerIndex].sections[sectionIndex]

    if (this.currentPlayers.length > 0) {
      for (const player of this.currentPlayers) {
        if (player !== nextPlayer && player.isPlaying) {
          player.play()
        }
      }
    }
    this.currentPlayers = [nextPlayer]
    nextPlayer.togglePlay()
  }

  @mutation togglePlayers(players: SectionPlayer[]) {
    let allSame = false
    if (this.currentPlayers.length > 0) {
      allSame = true
      for (const nextPlayer of players) {
        const isCurrent = this.currentPlayers.find(
          (currentPlayer) => currentPlayer === nextPlayer
        )
        if (isCurrent === undefined) {
          allSame = false
        }
      }
    }
    if (allSame) {
      if (players[0].isReady) {
        for (const nextPlayer of players) {
          nextPlayer.play()
        }
      } else {
        for (const nextPlayer of players) {
          nextPlayer.stop()
        }
      }
    } else {
      for (const player of this.currentPlayers) {
        player.stop()
      }
      for (const nextPlayer of players) {
        nextPlayer.play()
      }
      this.currentPlayers = players
    }
  }

  @mutation setupTransportTimeline(padPlayers: PadPlayer[]) {
    Tone.Transport.cancel(0)
    const durations = padPlayers.map((player) => player.topPlayer.duration)
    const startTimes = durations.reduce(
      (startTimes: number[], duration: number) => {
        const lastStartTime = startTimes[startTimes.length - 1]
        const newStartTime = lastStartTime + duration
        startTimes.push(newStartTime)
        return startTimes
      },
      [0]
    )
    for (const [i, padPlayer] of padPlayers.entries()) {
      Tone.Transport.schedule(() => {
        if (i === 0) {
          if (this.currentPadPlayer !== null) {
            this.currentPadPlayer.stop()
          }
          padPlayer.play()
        } else {
          padPlayers[i - 1].stop()
          padPlayer.play()
        }
        this.currentPadPlayer = padPlayer
      }, startTimes[i])
    }
  }

  // Odd signature required due to onPadPlayerLoopEnd not being callable if defined as a ordinary member function
  onPadPlayerLoopEnd = (mixer: MixEngine, padPlayer: PadPlayer) => {
    if (mixer.mode === MixerMode.interactive) return
    if (padPlayer !== mixer.currentPadPlayer) {
      // eslint-disable-next-line no-console
      console.error(`Logic error: ${padPlayer} is not currentPadPlayer`)
      return
    }
    const index = mixer.mashupSequencePadPlayers.findIndex(
      (p) => p === mixer.currentPadPlayer!
    )
    if (index === -1) {
      // eslint-disable-next-line no-console
      console.warn(`Logic error: currentPadPlayer not found in mashup sequence`)
    } else if (index >= mixer.mashupSequencePadPlayers.length - 1) {
      const nextPadPlayer = mixer.mashupSequencePadPlayers[0]
      nextPadPlayer.play()
      mixer.currentPadPlayer?.stop()
      mixer.currentPadPlayer = nextPadPlayer
    } else {
      const nextPadPlayer = mixer.mashupSequencePadPlayers[index + 1]
      nextPadPlayer.play()
      mixer.currentPadPlayer?.stop()
      mixer.currentPadPlayer = nextPadPlayer
    }
  }

  @mutation play() {
    Tone.Transport.start()
    if (this.audioContext?.state === 'suspended') {
      this.audioContext?.resume()
    }
  }

  @mutation pause() {
    Tone.Transport.pause()
    if (this.audioContext?.state === 'running') {
      this.audioContext?.suspend()
    }
  }

  @mutation stop() {
    Tone.Transport.stop()
  }

  get isLoopEnabled(): boolean {
    if (this.stemPlayers[0] === undefined) return true
    if (this.stemPlayers[0].sections[0] === undefined) return true
    return this.stemPlayers[0].sections[0].loopEnabled
  }

  @mutation toggleLooping() {
    if (this.isLoopEnabled) this.disableLooping()
    else this.enableLooping()
  }

  @mutation enableLooping() {
    this.stemPlayers.forEach((stemPlayer) => {
      stemPlayer.sections.forEach((sectionPlayer) => {
        sectionPlayer.sendMessageToAudioScope({ loopEnabled: true })
      })
    })
  }

  @mutation disableLooping() {
    this.stemPlayers.forEach((stemPlayer) => {
      stemPlayer.sections.forEach((sectionPlayer) => {
        sectionPlayer.sendMessageToAudioScope({ loopEnabled: false })
      })
    })
  }

  onMessageFromAudioScope(songIndex: number, sectionIndex: number) {
    return (message: object) => {
      const msg = message as ProcessorMessage
      if ('posFrames' in msg) {
        const sectionPlayer = this.stemPlayers[songIndex].sections[sectionIndex]
        const currentFrame = sectionPlayer.stems.startFrame + msg.posFrames
        const latencyFrames =
          this.audioContext!.baseLatency * this.audioContext!.sampleRate
        this.songTransportTime =
          (currentFrame - latencyFrames) / this.audioContext!.sampleRate
        sectionPlayer.currentBeat = msg.beats
        sectionPlayer.currentSixteenths = msg.sixteenths
        // console.log(
        //   `transport time: ${this.songTransportTime}; ${startFrame}; ${msg.posFrames}`
        // )
      }
    }
  }
}

export { State, Mode, SectionPlayer, StemPlayer, Send, ProcessorMessage }
