




import { defineComponent } from '@vue/composition-api'
import firebase from 'firebase'
import 'firebaseui/dist/firebaseui.css'
import { auth } from '~/services/firebase.service'
import { useUserService } from '~/services/user.service'

let firebaseui: any | null = null
let ui: any | null = null

export default defineComponent({
  emits: ['on-request-dismiss-sign-in-dialog'],
  props: {
    successfulSignInRoute: {
      type: String,
      default: '/',
    },
    showSignInLater: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      user: useUserService(),
    }
  },
  data() {
    return { isSigningInAnonymously: false }
  },
  async mounted() {
    if (!ui) {
      firebaseui = await import('firebaseui')
      ui = new firebaseui.auth.AuthUI(auth)
    }
    const signInOptions = [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        scopes: ['public_profile', 'email'],
      },
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      },
    ]
    if (this.showSignInLater) {
      signInOptions.push(firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID)
    }
    const uiConfig = {
      signInFlow: 'popup',
      signInOptions,
      callbacks: {
        signInSuccessWithAuthResult: this.onSignInSuccess,
      },
    }
    ui.start('#firebaseui-auth-container', uiConfig)
  },
  methods: {
    onSignInSuccess(credential: firebase.auth.UserCredential): boolean {
      const saveCredential = async () => {
        await this.user.setUserCredential(credential)
        this.$nuxt.$emit('on-request-dismiss-sign-in-dialog')
        this.$router.go(0)
      }
      saveCredential()
      return false
    },
  },
})
