













import { defineComponent } from '@vue/composition-api'
import { SongPlayer } from '~/store/song.vuex'
import { shortenText } from '~/utils/string'

export default defineComponent({
  props: {
    songPlayer: {
      type: Object,
      required: true,
      validator(value: SongPlayer): value is SongPlayer {
        return (value as SongPlayer) !== undefined
      },
    },
  },

  methods: {
    togglePlayPause() {
      const player = this.songPlayer as SongPlayer
      if (player.isPlaying) {
        player.audio.pause()
        player.isPlaying = false
      } else {
        player.audio.play()
        player.isPlaying = true
      }
    },
    shorten(text: string) {
      const shortened = shortenText(text)
      if (this.$vuetify.breakpoint.name === 'xs') {
        const maxLength = 17
        if (shortened.length > maxLength) {
          return shortened.slice(0, maxLength) + '...'
        }
      }
      return shortened
    },
  },
})
