export function shortenText(text: string): string {
  const re = /^[^(-]*/g
  const result = text.match(re)
  return result ? result[0].trim() : text
}

export function toKebabCase(str: string) {
  if (str == null) return str
  return str
    .match(
      /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
    )!
    .map((word) => word.toLowerCase())
    .join('-')
}
