export function shuffleArrayInPlace<T>(array: T[]): void {
  for (let i = array.length - 1; i > 0; i--) {
    const j: number = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
}

export function shuffleArray<T>(array: T[]): T[] {
  const newArray = array.slice()
  shuffleArrayInPlace(newArray)
  return newArray
}

// Use deep-equal instead
export function arrayOfStringsAreEqual(
  array1: string[] | null | undefined,
  array2: string[] | null | undefined
): boolean {
  let arraysAreEqual = false
  if (array1 == null) {
    if (array2 == null) {
      arraysAreEqual = true
    } else {
      arraysAreEqual = false
    }
  } else if (Array.isArray(array1)) {
    if (Array.isArray(array2)) {
      if (array1.length !== array2.length) {
        arraysAreEqual = false
      } else {
        arraysAreEqual = array1.every(
          (artist, index) => artist === array2[index]
        )
      }
    } else {
      arraysAreEqual = false
    }
  } else {
    arraysAreEqual = false
  }
  return arraysAreEqual
}
