import { render, staticRenderFns } from "./default.vue?vue&type=template&id=c22c683c&lang=pug&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Login: require('/home/runner/work/nusic.live/nusic.live/components/Login.vue').default,MiniSongPlayer: require('/home/runner/work/nusic.live/nusic.live/components/global/MiniSongPlayer.vue').default,RequireInteractionDialog: require('/home/runner/work/nusic.live/nusic.live/components/global/RequireInteractionDialog.vue').default,NotificationDialog: require('/home/runner/work/nusic.live/nusic.live/components/NotificationDialog.vue').default,NewContentRefreshDialog: require('/home/runner/work/nusic.live/nusic.live/components/NewContentRefreshDialog.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VApp,VAppBar,VAppBarNavIcon,VBottomNavigation,VBtn,VContainer,VDialog,VFooter,VIcon,VImg,VList,VListGroup,VListItem,VListItemAction,VListItemContent,VListItemIcon,VListItemTitle,VMain,VMenu,VNavigationDrawer,VSpacer,VToolbarTitle})
