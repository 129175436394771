import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import devConfig from '../firebase.dev.config'

export const devApp =
  firebase.apps.find((app) => {
    return app.name === 'dev'
  }) ?? firebase.initializeApp(devConfig, 'dev')
// export const devApp = firebase.initializeApp(devConfig, 'dev')
export const devDb = devApp.firestore()
export const devAuth = devApp.auth()
export const GoogleAuthProvider = new firebase.auth.GoogleAuthProvider()
export const FacebookAuthProvider = new firebase.auth.FacebookAuthProvider()
export default firebase
