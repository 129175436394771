export enum GoogleAnalyticsEventType {
  login = 'login',
  logout = 'logout',

  //  New users signing up for the first time
  signUp = 'sign_up',
  //  New users signing up is successful
  signUpSuccessful = 'sign_up_successful',
  //  New users signing up has failed
  signUpFailed = 'sign_up_failed',

  playMashup = 'play_mashup',
  pauseMashup = 'pause_mashup',
  skipNextMashup = 'skip_next_mashup',
  likeMashup = 'like_mashup',
  remixInApp = 'remix_in_app',
  notificationPermissionGranted = 'notification_permission_granted',
  notificationPermissionDenied = 'notification_permission_denied',
}
