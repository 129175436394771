export default {
  apiKey: 'AIzaSyAr1PNgyrkDVv0ymgojuy37q_A8Qh1IAbk',
  authDomain: 'nusic-mashup-dj.firebaseapp.com',
  databaseURL: 'https://nusic-mashup-dj.firebaseio.com',
  projectId: 'nusic-mashup-dj',
  storageBucket: 'nusic-mashup-dj.appspot.com',
  messagingSenderId: '262530209221',
  appId: '1:262530209221:web:6b529b0e9d94505a2c27ac',
  measurementId: 'G-PVJLBM98MW',
}
