import { ref, watch, computed } from '@vue/composition-api'

let isComponentInitialized = false

const INTERACTIVIY_ARRAY = [
  'keypress',
  'keydown',
  'click',
  'contextmenu',
  'dblclick',
  'touchmove',
  'touchstart',
]

const isUserInteractive = ref(false)
const showInteractivityDialog = ref(false)
const isUserNotInteractive = computed(() => isUserInteractive.value === false)

export function useInteractivityService() {
  if (!isComponentInitialized) {
    isComponentInitialized = true
    INTERACTIVIY_ARRAY.forEach((event) => {
      window.addEventListener(event, onUserInteractivity, false)
    })
  }
  return {
    isUserInteractive,
    showInteractivityDialog,
    checkInteractionOrShowDialog,
  }
}

function onUserInteractivity() {
  INTERACTIVIY_ARRAY.forEach((event) => {
    window.removeEventListener(event, onUserInteractivity, false)
  })
  isUserInteractive.value = true
}

function checkInteractionOrShowDialog(): Promise<void> {
  if (isUserNotInteractive.value === true) {
    showInteractivityDialog.value = true

    return new Promise((resolve) => {
      watch(isUserInteractive, (isInteracted: boolean) => {
        if (isInteracted) {
          showInteractivityDialog.value = false
          resolve()
        }
      })
    })
  } else {
    return Promise.resolve()
  }
}
