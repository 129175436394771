








import { defineComponent, ref } from '@vue/composition-api'
import { useInteractivityService } from '~/services/interactivity.service'

export default defineComponent({
  data() {
    return {
      showInteractivityDialog: ref(false),
    }
  },
  mounted() {
    const { showInteractivityDialog } = useInteractivityService()
    this.showInteractivityDialog = showInteractivityDialog
  },
  methods: {
    onInteractClick() {},
  },
})
