













import { defineComponent } from '@vue/composition-api'
import { firebaseMessaging } from '~/services/cloud-messaging.service'

export default defineComponent({
  data() {
    return {
      isShowNotification: false,
      title: '',
      body: '',
      clickActionUrl: '',
    }
  },
  mounted() {
    if (firebaseMessaging) {
      firebaseMessaging.onMessage((payload: any) => {
        // eslint-disable-next-line no-console
        console.log(
          '[firebase-messaging-sw.js] Received foreground message ',
          payload
        )
        // eslint-disable-next-line camelcase
        const {
          title,
          body,
          click_action: clickActionUrl,
        } = payload.notification
        this.title = title
        this.body = body
        this.clickActionUrl = clickActionUrl
        this.isShowNotification = true
      })
    }
  },
  methods: {
    onClickAction(): void {
      this.isShowNotification = false
      this.$router.push(this.clickActionUrl)
    },
  },
})
