

































































































































import { defineComponent } from '@vue/composition-api'
import { vxm, SongPlayer } from '~/store/store.vuex'
import MiniSongPlayer from '~/components/global/MiniSongPlayer.vue'
import { GoogleAnalyticsEventType } from '~/models/analytics'
import Login from '~/components/Login.vue'
import NotificationDialog from '~/components/NotificationDialog.vue'
import NewContentRefreshDialog from '~/components/NewContentRefreshDialog.vue'
import { useUserService } from '~/services/user.service'

type NavigationItem = {
  bottomNavigation?: boolean
  icon: string
  title: string
  to: string
  subLinks?: NavigationItem[]
  requiredUserRoles?: string[]
}

export default defineComponent({
  components: {
    MiniSongPlayer,
    Login,
    NotificationDialog,
    NewContentRefreshDialog,
  },
  setup() {
    return {
      user: useUserService(),
    }
  },
  data: () => ({
    bottomNavigation: 'Home',
    drawer: false,
    fixed: true,
    items: [
      {
        title: 'Home',
        icon: 'mdi-home',
        to: '/',
        bottomNavigation: true,
      },
      {
        title: 'Songs',
        icon: 'mdi-playlist-music',
        to: '/songs',
        bottomNavigation: true,
        requiredUserRoles: ['admin'],
      },
      {
        title: 'Timestretch',
        icon: 'mdi-music-accidental-natural',
        to: '/mashup/timestretch',
        bottomNavigation: true,
        requiredUserRoles: ['admin'],
      },
      {
        title: 'Mashup',
        icon: 'mdi-motion-play-outline',
        to: '/mashup/mashpad',
        bottomNavigation: true,
        requiredUserRoles: ['admin'],
      },
      {
        title: 'Charts',
        icon: 'mdi-poll',
        to: '/charts',
        bottomNavigation: true,
      },
      {
        icon: 'mdi-briefcase-variant',
        title: 'Assets',
        to: '/assets',
        requiredUserRoles: ['admin', 'creator'],
        bottomNavigation: true,
      },
      {
        icon: 'mdi-chart-bubble',
        title: 'Inspire',
        to: '/inspire',
        requiredUserRoles: ['admin'],
      },
    ] as NavigationItem[],

    itemsWithSublinks: [
      {
        title: 'Analytics',
        icon: 'mdi-chart-areaspline',
        to: '/analytics',
        subLinks: [
          {
            title: 'Dashboard',
            icon: 'mdi-monitor-dashboard',
            to: '/analytics/dashboard',
          },
          {
            title: 'Mashups',
            icon: 'mdi-book-music',
            to: '/analytics/mashups',
          },
          // {
          //   title: 'Requests',
          //   icon: 'mdi-basket-fill',
          //   to: '/analytics/requests',
          // },
          {
            title: 'Songs',
            icon: 'mdi-music-box-multiple',
            to: '/analytics/songs',
          },
          {
            title: 'Users',
            icon: 'mdi-account-multiple',
            to: '/analytics/users',
          },
        ],
      },
      {
        title: 'Console',
        icon: 'mdi-monitor-edit',
        to: '/console',
        subLinks: [
          {
            title: 'Dashboard',
            icon: 'mdi-console-network-outline',
            to: '/console/dashboard',
          },
          // {
          //   title: 'Song, Artists, Genres',
          //   icon: 'mdi-book-music',
          //   to: '/console/songs',
          // },
          {
            title: 'Song Editor',
            icon: 'mdi-file-music-outline',
            to: '/console/song-editor',
          },
        ],
      },
    ] as NavigationItem[],

    miniVariant: false,
    right: true,
    rightDrawer: false,
    title: 'NUSIC',
    song: vxm.song,
    hasNotSignedInBefore: true,
    signInDialogIsShown: false,
  }),
  computed: {
    showSignIn(): boolean {
      const routeName = this.$route.name ?? ''
      return (
        this.user.state.isSignedOut &&
        !['charts', 'charts-mashup'].includes(routeName)
      )
    },
    backgroundImageSize() {
      if (this.$vuetify.breakpoint.mobile) {
        return 'medium'
      } else {
        return 'large'
      }
    },
    bottomNavigationItems(): NavigationItem[] {
      const isAdmin = this.isAdmin
      if (isAdmin) {
        return this.items
      } else {
        return this.items.filter((item) => {
          const isNonAdminItem =
            !item.requiredUserRoles?.includes('admin') && item.bottomNavigation
          if (this.isCreator) {
            return isNonAdminItem || item.requiredUserRoles?.includes('creator')
          } else {
            return isNonAdminItem
          }
        })
      }
    },
    currentPlayer(): SongPlayer | null {
      return this.song.currentPlayer
    },
    enableNavigationDrawer(): boolean {
      return !!this.user.state.isSignedIn && !this.$vuetify.breakpoint.mobile
    },
    signOutAttributes(): object {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return { fab: true, small: true }
        default:
          return {}
      }
    },
    isAppBarVisible(): boolean {
      const isDesktop = !this.$vuetify.breakpoint.mobile
      return isDesktop || this.showSignIn
    },
    isAdmin(): boolean {
      return this.user.isAdmin.value
    },
    isCreator(): boolean {
      return this.user.isCreator.value
    },
    itemsToDisplay(): NavigationItem[] {
      const isAdmin = this.isAdmin
      if (isAdmin) {
        return this.items
      } else {
        return this.items.filter((item) => {
          const isNonAdminItem = !item.requiredUserRoles?.includes('admin')
          if (this.isCreator) {
            return isNonAdminItem || item.requiredUserRoles?.includes('creator')
          } else {
            return isNonAdminItem
          }
        })
      }
    },
    showSignInLater(): boolean {
      return (
        ['charts', 'charts-mashup'].includes(this.$route.name ?? '') &&
        !this.user.state.isSignedInAnonymously
      )
    },
    pageStyles(): string {
      switch (this.$route.name) {
        case 'charts':
          return 'background: #32353E'
        case 'charts-mashup':
          return 'background: #262932'
        default:
          return ''
      }
    },
  },
  created() {
    this.$nuxt.$on('on-request-sign-in-dialog', () => {
      this.signInDialogIsShown = true
    })
    this.$nuxt.$on('on-request-dismiss-sign-in-dialog', () => {
      this.signInDialogIsShown = false
    })
  },
  async mounted() {
    // TODO: Create a more intelligent song pre-caching mechanism
    // auth.onAuthStateChanged((firebaseUser) => {
    //   if (firebaseUser) {
    //     this.song.getSongPlayers()
    //     this.hasNotSignedInBefore = false
    //     // eslint-disable-next-line no-console
    //     console.log(`🤘 Signed in as ${firebaseUser.email}`)
    //   } else {
    //     // eslint-disable-next-line no-console
    //     console.log(`👋 Signed out`)
    //   }
    // })
    // This has been handled by NewContentRefreshDialog component
    // @ts-ignore
    // const workbox = await window.$workbox
    // if (workbox) {
    //   workbox.addEventListener(
    //     'installed',
    //     (event: Event & { isUpdate: true }) => {
    //       // If we don't do this we'll be displaying the notification after the initial installation, which isn't perferred.
    //       if (event.isUpdate) {
    //         // whatever logic you want to use to notify the user that they need to refresh the page.
    //       }
    //     }
    //   )
    // }
  },
  methods: {
    // async signIn(isFacebook: boolean) {
    //   if (isFacebook) {
    //     await this.user.signInWithFacebook()
    //     this.$gtag.event(GoogleAnalyticsEventType.login)
    //     this.$gtag.set({ 'sign-in-method': FacebookAuthProvider.providerId })
    //   } else {
    //     await this.user.signIn()
    //     this.$gtag.event(GoogleAnalyticsEventType.login)
    //     this.$gtag.set({ 'sign-in-method': GoogleAuthProvider.providerId })
    //   }
    // },
    signOut() {
      this.user.signOut()
      this.signInDialogIsShown = false
      this.$gtag.event(GoogleAnalyticsEventType.logout)
      this.$router.push('/')
    },
  },
  beforeDestroy() {
    this.$nuxt.$off('on-request-sign-in-dialog')
    this.$nuxt.$off('on-request-dismiss-sign-in-dialog')
  },
})
