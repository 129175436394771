import { Plugin } from '@nuxt/types'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import config from '../firebase.prod.config'
import { Idle } from '~/services/idle.service'

declare namespace window {
  function onNuxtReady(callback: () => void): void
}
const AppInitPlugin: Plugin = ({ isDev }) => {
  if (isDev) {
    // eslint-disable-next-line no-console
    console.log('🏗 Development mode')
  }
  if (!firebase.apps.length) {
    firebase.initializeApp(config)
  }
  firebase
    .firestore()
    .enablePersistence({ synchronizeTabs: true })
    .then(() => {
      // eslint-disable-next-line no-console
      console.log('🔥 Firebase persistance enabled')
    })
    .catch(function (err) {
      if (err.code === 'failed-precondition') {
        // eslint-disable-next-line no-console
        console.warn(
          'Unable to enable firestore persistance in more than one browser tab at a time.'
        )
      } else if (err.code === 'unimplemented') {
        // eslint-disable-next-line no-console
        console.warn(
          'The current browser does not support all of the features required to enable persistence.'
        )
      }
    })

  window.onNuxtReady(() => {
    // eslint-disable-next-line no-console
    console.log('⛰ Nusic is ready and mounted')
    const idle = new Idle({})

    idle.onInteractivity = () => {
      idle.onInteractivity = () => {}
      //  TODO: Create middleware which initializes mix-engine
      //  TODO: When moving change vuex to composition api
      // Initialize MixEngine
      // vxm.mixer
      //   .initialize()
      //   .then(() => {
      //     // eslint-disable-next-line no-console
      //     console.log('🔊 MixEngine is ready')
      //   })
      //   .catch((err) => {
      //     // eslint-disable-next-line no-console
      //     console.error(`Error initializing MixEngine ${err}`)
      //   })
    }
  })
}
export default AppInitPlugin
