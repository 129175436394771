export default {
  apiKey: 'AIzaSyC1wtvL-RXFyMMBu4uCBlP_WlPzxP6XrTA',
  authDomain: 'nusic-mashups-dev.firebaseapp.com',
  databaseURL: 'https://nusic-mashups-dev.firebaseio.com',
  projectId: 'nusic-mashups-dev',
  storageBucket: 'nusic-mashups-dev.appspot.com',
  messagingSenderId: '435413607318',
  appId: '1:435413607318:web:230b61592242452930b3b7',
  measurementId: 'G-5N5DVSDH6G',
}
