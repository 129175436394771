import { Middleware } from '@nuxt/types'
import { useUserService } from '~/services/user.service'

const creatorMiddleware: Middleware = async ({ redirect }) => {
  const user = useUserService()
  try {
    await user.userAuthenticationSuccessful()
    if (!user.isAdmin.value && !user.isCreator.value) {
      throw new Error('Not authorized to use this route!')
    }
  } catch (err) {
    return redirect('/')
  }
}

export default creatorMiddleware
