import firebase from 'firebase'
import { event } from 'vue-gtag'
import { UserDocument } from '~/models/user'
import { GoogleAnalyticsEventType } from '~/models/analytics'
export interface UserService {
  currentUserDocument: UserDocument | null
  getOrCreateUserDocument(
    credential: firebase.auth.UserCredential | null
  ): Promise<UserDocument>
}

export class FirestoreUserService implements UserService {
  currentUserDocument: UserDocument | null = null
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public db: firebase.firestore.Firestore,
    public auth: firebase.auth.Auth
  ) {}

  async getUserDocument(): Promise<UserDocument | null> {
    if (this.auth.currentUser !== null) {
      const id: string = this.auth.currentUser.uid
      const doc = await this.db.collection('users').doc(id).get()
      if (doc.exists) {
        this.currentUserDocument = doc.data() as UserDocument
        return this.currentUserDocument
      } else {
        return null
      }
    } else {
      throw new Error('User must be signed in.')
    }
  }

  async getOrCreateUserDocument(
    credential: firebase.auth.UserCredential | null = null
  ): Promise<UserDocument> {
    if (this.auth.currentUser !== null) {
      const id: string = this.auth.currentUser.uid
      const doc = await this.db.collection('users').doc(id).get()
      if (doc.exists) {
        this.currentUserDocument = doc.data() as UserDocument
        return this.currentUserDocument
      } else {
        event(GoogleAnalyticsEventType.signUp)
        const user: firebase.User = this.auth.currentUser
        user.photoURL =
          this.getProfilePicFromCredential(credential) ?? user.photoURL
        const userDoc = new UserDocument(user)
        await this.db
          .collection('users')
          .doc(id)
          .set({ ...userDoc })
        await this.db.collection
        event(GoogleAnalyticsEventType.signUpSuccessful)
        this.currentUserDocument = userDoc
        //  TODO: Will there be a case where sign up fails? Add signUpFailed event.
        return this.currentUserDocument
      }
    } else {
      throw new Error('User must be signed in.')
    }
  }

  async updateUserProfilePic(photoUrl: string): Promise<void> {
    if (this.auth.currentUser !== null) {
      const id: string = this.auth.currentUser.uid
      await this.db.collection('users').doc(id).update({ photoUrl })
    } else {
      throw new Error('User must be signed in.')
    }
  }

  getProfilePicFromCredential(
    credential: firebase.auth.UserCredential | null
  ): string | null {
    return (
      (credential?.additionalUserInfo?.profile as {
        picture: {
          data: { url: string }
        }
        id: string
      })?.picture?.data?.url ?? null
    )
  }

  async updateUserDocument(obj: Partial<UserDocument>): Promise<void> {
    if (this.auth.currentUser !== null) {
      const id: string = this.auth.currentUser.uid
      await this.db.collection('users').doc(id).update(obj)
    } else {
      throw new Error('User must be signed in.')
    }
  }
}
