/* eslint-disable no-unused-vars */
import VueCompositionApi, {
  reactive,
  computed,
  watch,
  toRef,
  Ref,
  ref,
} from '@vue/composition-api'
import firebase from 'firebase'
import Vue from 'vue'
import { event } from 'vue-gtag'
import { FirestoreUserService } from './firestore.user.service'
import { firebaseMessaging } from './cloud-messaging.service'
import {
  db as prodDb,
  auth as prodAuth,
  GoogleAuthProvider,
} from '~/services/firebase.service'
import { devDb, devAuth } from '~/services/firebase.dev.service'
import { UserDocument } from '~/models/user'
import { GoogleAnalyticsEventType } from '~/models/analytics'

let auth: firebase.auth.Auth
let db: firebase.firestore.Firestore

// To run unit tests
Vue.use(VueCompositionApi)

export interface UserService {
  isSignedIn: null | boolean
  isSignedInWithProvider: null | boolean
  isSignedInAnonymously: null | boolean
  firebaseUser: firebase.User | null
  firebaseDevUser: firebase.User | null
  isSignedOut: boolean
  email: string | null | undefined
  displayName: string | null | undefined
  photoURL: string | null | undefined
  userService: FirestoreUserService | null
  isUserAuthenticated: boolean | null
  userDocument: UserDocument | null
}

const state: UserService = reactive({
  isSignedInWithProvider: null,
  isSignedInAnonymously: null,
  isSignedIn: computed(() => state.isSignedInWithProvider),
  firebaseUser: null as firebase.User | null,
  firebaseDevUser: null as firebase.User | null,
  isSignedOut: computed(() => !state.isSignedIn),
  userDocument: null,
  email: computed(() => state.userDocument?.email),
  displayName: computed(() => state.userDocument?.name),
  photoURL: computed(() => state.userDocument?.photoUrl),
  userService: null as FirestoreUserService | null,
  isUserAuthenticated: computed(
    () => state.isSignedInWithProvider || state.isSignedInAnonymously
  ),
})
const isAdmin = ref(false)
const isCreator = ref(false)
let isPermissionGranted: boolean = false
if ('Notification' in window) {
  isPermissionGranted = Notification.permission === 'granted'
}
const isNotificationEnabled = ref(isPermissionGranted)

prodAuth.onAuthStateChanged(async (firebaseUser) => {
  if (firebaseUser) {
    state.userService = new FirestoreUserService(db, auth)
    const credential = await getUserCredential()
    state.userDocument = await state.userService.getOrCreateUserDocument(
      credential
    )
    isAdmin.value =
      state.userService.currentUserDocument?.roleIds?.includes('admin') ?? false
    isCreator.value =
      state.userService.currentUserDocument?.roleIds?.includes('creator') ??
      false
    state.firebaseUser = firebaseUser
    if (firebaseUser.isAnonymous) {
      state.isSignedInAnonymously = true
      state.isSignedInWithProvider = false
    } else {
      state.isSignedInWithProvider = true
    }
  } else {
    state.userService = null
    state.isSignedInAnonymously = false
    state.isSignedInWithProvider = false
  }
})

export function useUserService(isDev: boolean = false) {
  auth = isDev ? devAuth : prodAuth
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  db = isDev ? devDb : prodDb
  // FIXME: Avoid multiple subscription but support both dev and prod auth services
  return {
    state,
    isAdmin,
    isCreator,
    isNotificationEnabled,
    signIn,
    signOut,
    signInToFirebaseDev,
    isSignInSuccessful,
    getLikedMashupIds,
    likeMashup,
    unlikeMashup,
    subscribeToUserMashupLikes,
    signInAnonymously,
    userAuthenticationSuccessful,
    setUserCredential,
    getNotificationPermission,
  }
}

function isSignInSuccessful(timeout: number = 5000): Promise<void> {
  return new Promise((resolve, reject) => {
    if (state.isSignedIn) {
      resolve()
    }
    const notSignedInTimer: NodeJS.Timeout = setTimeout(() => {
      unwatch()
      reject(new Error('User is not signed in.'))
    }, timeout)
    const unwatch = watch(toRef(state, 'isSignedIn'), function (newVal) {
      clearTimeout(notSignedInTimer)
      unwatch()
      if (newVal) {
        resolve()
      } else {
        reject(new Error('User is not signed in.'))
      }
    })
  })
}

function userAuthenticationSuccessful(timeout: number = 5000): Promise<void> {
  return new Promise((resolve, reject) => {
    if (state.isSignedInWithProvider || state.isSignedInAnonymously) {
      resolve()
    }
    // TODO: Understand why performance.now is required to load the site faster
    performance.now()
    const notSignedInTimer: NodeJS.Timeout = setTimeout(() => {
      unwatch()
      reject(new Error('User is not signed in.'))
    }, timeout)

    const unwatch = watch(
      toRef(state, 'isUserAuthenticated'),
      function (newVal) {
        clearTimeout(notSignedInTimer)
        unwatch()
        if (newVal) {
          resolve()
        } else {
          reject(new Error('User is not signed in.'))
        }
      }
    )
  })
}

async function signIn() {
  await signInWithPopup()
}

function signOut() {
  prodAuth.signOut()
  devAuth.signOut()
}

async function signInWithPopup() {
  const provider = GoogleAuthProvider
  provider.addScope('profile')
  provider.addScope('email')
  const credential = await auth.signInWithPopup(provider)
  await setUserCredential(credential)
}

async function setUserCredential(credential: firebase.auth.UserCredential) {
  if (credential.user == null) return
  const userCache = await window.caches.open('user-cache')
  const jsonstring = JSON.stringify(credential, null, 2)
  let createdAt = 'unknown'
  try {
    createdAt = new Date(Date.now()).toISOString()
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(`${err} createdAt is undefined`)
  }

  const songResponse = new Response(jsonstring, {
    // @ts-ignore
    headers: {
      'Content-Type': 'application/json',
      'Content-Length': jsonstring.length,
      'Created-At': createdAt,
    },
  })
  await userCache.put(
    new Request('https://google.com/userCredential'),
    songResponse
  )
}

async function getUserCredential(): Promise<firebase.auth.UserCredential | null> {
  const userCache = await window.caches.open('user-cache')
  const response = await userCache.match('https://google.com/userCredential')
  if (response != null) {
    const credential = (await response.json()) as firebase.auth.UserCredential
    return credential
  } else return null
}

async function signInToFirebaseDev() {
  if (devAuth.currentUser != null) return
  const userCredential = await getUserCredential()
  if (userCredential == null || userCredential.credential == null) {
    // eslint-disable-next-line no-console
    signOut()
    return
  }
  // https://stackoverflow.com/questions/41651589/firebase-signinwithcredential-failed-first-argument-credential-must-be-a-vali
  const authCredential = userCredential.credential as firebase.auth.AuthCredential & {
    oauthIdToken: string | null | undefined
  }
  try {
    if (authCredential.oauthIdToken == null) {
      throw new Error('oauthIdToken is undefined')
    }
    const token = authCredential.oauthIdToken
    const credential = firebase.auth.GoogleAuthProvider.credential(token)
    await devAuth.signInWithCredential(credential)
    state.firebaseDevUser = devAuth.currentUser
    // eslint-disable-next-line no-console
    console.log(`👩‍🎤 Signed-in to nusic-mashups-dev`)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(
      `Error: Unable to sign-in to firebase nusic-mashups-dev: ${err}`
    )
    signOut()
  }
}

async function getLikedMashupIds(): Promise<string[]> {
  if (state.firebaseUser) {
    const likedMashupDocs = await db
      .collection(`users/${state.firebaseUser.uid}/likes`)
      .where('kind', '==', 'mashup')
      .get()
    const mashupIds = likedMashupDocs.docs.map((doc) => doc.id)
    return mashupIds
  } else {
    throw new Error('User is not signed in')
  }
}

async function likeMashup(mashupId: string): Promise<void> {
  if (state.firebaseUser) {
    db.collection('mashups')
      .doc(mashupId)
      .update({ likes: firebase.firestore.FieldValue.increment(1) })
    const mashupLikeRef = db
      .collection(`users/${state.firebaseUser.uid}/likes`)
      .doc(mashupId)
    await mashupLikeRef.set({
      kind: 'mashup',
    })
    await db
      .collection(`users/${state.firebaseUser.uid}/likes`)
      .doc('--stats--')
      .set(
        {
          totalMashups: firebase.firestore.FieldValue.increment(1),
        },
        {
          merge: true,
        }
      )
  } else {
    throw new Error('User is not signed in')
  }
}

async function unlikeMashup(mashupId: string): Promise<void> {
  if (state.firebaseUser) {
    db.collection('mashups')
      .doc(mashupId)
      .update({ likes: firebase.firestore.FieldValue.increment(-1) })
    const mashupLikeRef = db
      .collection(`users/${state.firebaseUser.uid}/likes`)
      .doc(mashupId)
    await mashupLikeRef.delete()
    await db
      .collection(`users/${state.firebaseUser.uid}/likes`)
      .doc('--stats--')
      .set(
        {
          totalMashups: firebase.firestore.FieldValue.increment(-1),
        },
        {
          merge: true,
        }
      )
  } else {
    throw new Error('User is not signed in')
  }
}

function subscribeToUserMashupLikes(
  refToLikedMashupIds: Ref<string[]>
): Promise<Function> {
  return new Promise((resolve, reject) => {
    try {
      const unsubscribe = db
        .collection(`users/${state.firebaseUser?.uid}/likes`)
        .onSnapshot((querySnapshot) => {
          const likedIds: string[] = []
          querySnapshot.forEach((mashupId) => {
            likedIds.push(mashupId.id)
          })
          refToLikedMashupIds.value = likedIds
          resolve(unsubscribe)
        })
    } catch (err) {
      reject(new Error(err))
    }
  })
}

async function signInAnonymously(): Promise<void> {
  const credential: firebase.auth.UserCredential = await auth.signInAnonymously()
  await setUserCredential(credential)
}

async function getNotificationPermission(): Promise<void> {
  if (firebaseMessaging === null) {
    return
  }
  const googleCloudMessagingToken = await firebaseMessaging.getToken({
    vapidKey: process.env.vapidKey,
  })
  if (googleCloudMessagingToken) {
    isNotificationEnabled.value = true
    event(GoogleAnalyticsEventType.notificationPermissionGranted)
    try {
      if (state.userService) {
        await state.userService?.updateUserDocument({
          googleCloudMessagingToken,
        })
      } else {
        throw new Error('User is not signed in.')
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  } else {
    event(GoogleAnalyticsEventType.notificationPermissionDenied)
  }
}
