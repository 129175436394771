import { createModule, mutation, action } from 'vuex-class-component'

import { Song } from 'models/song'
import { db } from '~/services/firebase.service'
import { shuffleArrayInPlace } from '~/utils/array'

export type SongPlayer = {
  id: string
  song: Song
  audio: HTMLAudioElement
  isPlaying: boolean
  transportTime: number
}

export class SongStore extends createModule({
  namespaced: 'song',
  strict: false,
  target: 'nuxt',
  enableLocalWatchers: true,
}) {
  songPlayers: SongPlayer[] = []
  currentPlayer: SongPlayer | null = null
  fetchingSongs = false
  get areRetrieved() {
    return Object.keys(this.songPlayers).length > 0
  }

  @action async getSongPlayers(): Promise<SongPlayer[]> {
    if (this.areRetrieved) {
      // eslint-disable-next-line no-console
      console.log('🎸 Songs are ready')
      return this.songPlayers
    }
    // eslint-disable-next-line no-console
    console.log('🎸 Fetching songs...')
    this.fetchingSongs = true

    // db.collection('songs')
    //   .where('schemaVersion', '==', 2)
    //   .onSnapshot({ includeMetadataChanges: true }, function (snapshot) {
    //     snapshot.docChanges().forEach(function (change) {
    //       if (change.type === 'added') {
    //         const song = <Song>change.doc.data()
    //         console.log('New song: ', song.title)
    //       }
    //       const source = snapshot.metadata.fromCache ? 'local cache' : 'server'
    //       console.log('Data came from ' + source)
    //     })
    //   })

    const snapshot = await db
      .collection('songs')
      .where('schemaVersion', '==', 2)
      .limit(50)
      .get()
    const songCache = await window.caches.open('song-cache')
    // const songPlayers: SongPlayer[] = []
    const promises: Promise<SongPlayer>[] = []
    snapshot.forEach((doc) => {
      const createSongPlayerPromise = async () => {
        const song = doc.data()
        const songID = doc.id
        const response = await songCache.match(songID)
        if (!response) {
          const jsonstring = JSON.stringify(song, null, 2)
          let createdAt = 'unknown'
          try {
            createdAt = new Date(song.createdAt.seconds * 1000).toISOString()
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(`${err} song.createdAt is undefined`)
          }

          const songResponse = new Response(jsonstring, {
            // @ts-ignore
            headers: {
              'Content-Type': 'application/json',
              'Content-Length': jsonstring.length,
              'Created-At': createdAt,
            },
          })
          await songCache.put(new Request(songID), songResponse)
        }
        const audioElement = new Audio(song.audioFileUrl)
        return {
          id: doc.id,
          song,
          audio: audioElement,
          isPlaying: false,
          transportTime: 0,
        } as SongPlayer
      }
      promises.push(createSongPlayerPromise())
    })
    const songPlayers = await Promise.all(promises)
    // FIXME: Re-enable
    // shuffleArrayInPlace(songPlayers)
    this.songPlayers = songPlayers
    this.fetchingSongs = false
    // eslint-disable-next-line no-console
    console.log('🎸 Songs are ready')
    return this.songPlayers
  }

  @mutation reshuffleSongList() {
    shuffleArrayInPlace(this.songPlayers)
  }
}
