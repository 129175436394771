type ValueOf<T> = T[keyof T]

export const mapObject = <Input extends object, Output>(
  obj: Input,
  mappingFn: (key: keyof Input, value: ValueOf<Input>) => Output
): Record<keyof Input, Output> => {
  const newObj = {} as Record<keyof Input, Output>
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      const oldValue = obj[key]
      newObj[key] = mappingFn(key, oldValue)
    }
  }
  return newObj
}
