import { Plugin } from '@nuxt/types'
import Vue from 'vue'
import VueGtag from 'vue-gtag'

const GoogleAnalyticsPlugIn: Plugin = () => {
  if (process.env.buildMode === 'prod') {
    Vue.use(VueGtag, {
      config: { id: 'G-PVJLBM98MW' },
      appName: 'nusic.live',
    })
  } else {
    Vue.use(VueGtag, {
      config: { id: 'G-5N5DVSDH6G' },
      appName: 'nusic-mashups-dev',
    })
  }
}

export default GoogleAnalyticsPlugIn
