import { extractVuexModule, createProxy } from 'vuex-class-component'
import Vue from 'vue'
import Vuex from 'vuex'
import { SongStore, SongPlayer } from './song.vuex'
import { UserStore } from './user.vuex'
import {
  MixEngine,
  SectionPlayer,
  StemPlayer,
  Send,
} from '~/services/mix.engine'
import { Song } from '~/models/song'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    ...extractVuexModule(UserStore),
    ...extractVuexModule(SongStore),
    ...extractVuexModule(MixEngine),
  },
})

// Creating proxies.
export const vxm = {
  user: createProxy(store, UserStore),
  song: createProxy(store, SongStore),
  mixer: createProxy(store, MixEngine),
}

export {
  Song,
  SongPlayer,
  SectionPlayer,
  StemPlayer,
  Send,
  UserStore,
  SongStore,
  MixEngine,
}
