var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"app-background",attrs:{"src":"/nusic-deck-frontpage.png","image-style":_vm.backgroundImageSize,"alt":"Nusic Hero"}}),_c('v-app',{attrs:{"dark":""}},[(_vm.enableNavigationDrawer)?_c('v-navigation-drawer',{attrs:{"mini-variant":_vm.miniVariant,"app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',[_vm._l((_vm.itemsToDisplay),function(item,i){return _c('v-list-item',{key:i,attrs:{"to":item.to,"router":"","exact":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)],1)}),_vm._l((_vm.itemsWithSublinks),function(item,i){return (_vm.isAdmin)?_c('v-list-group',{key:i + _vm.items.length,attrs:{"prepend-icon":item.icon,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)]},proxy:true}],null,true)},_vm._l((item.subLinks),function(subItem,j){return _c('v-list-item',{key:i * 10 + j,attrs:{"to":subItem.to,"router":"","exact":""}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(subItem.title)}}),_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(subItem.icon))])],1)],1)}),1):_vm._e()})],2)],1):_vm._e(),(_vm.isAppBarVisible)?_c('v-app-bar',{attrs:{"app":""}},[(_vm.enableNavigationDrawer)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('img',{staticClass:"nusic-icon",attrs:{"src":"/nusic-icon.png"}}),_c('v-toolbar-title',{domProps:{"textContent":_vm._s(_vm.title)}}),_c('v-spacer'),(_vm.showSignIn)?_c('v-dialog',{attrs:{"max-width":"400","content-class":"login-dialog","persistent":_vm.showSignInLater},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var _obj;

var on = ref.on;
var attrs = ref.attrs;return [_c('v-btn',_vm._g(_vm._b({class:( _obj = {}, _obj["hide-then-fade-in"] = _vm.hasNotSignedInBefore, _obj ),attrs:{"disabled":_vm.user.state.isSigningIn}},'v-btn',attrs,false),on),[_vm._v(_vm._s(!_vm.user.state.isSigningIn ? "Sign in" : "Signing In..."))])]}}],null,false,2762643700),model:{value:(_vm.signInDialogIsShown),callback:function ($$v) {_vm.signInDialogIsShown=$$v},expression:"signInDialogIsShown"}},[(_vm.signInDialogIsShown)?_c('Login',{attrs:{"path":_vm.$route.path,"showSignInLater":_vm.showSignInLater}}):_vm._e()],1):_vm._e(),(_vm.user.state.isSignedIn)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',Object.assign({}, _vm.signOutAttributes, attrs),false),on),[(_vm.user.state.photoURL)?_c('v-img',{staticClass:"mr-2 rounded-lg",attrs:{"src":_vm.user.state.photoURL,"max-height":"25","max-width":"25"}}):_vm._e(),_c('span',{staticClass:"d-none d-sm-inline ps-1"},[_vm._v(_vm._s(_vm.user.state.email))])],1)]}}],null,false,1480200491)},[_c('v-btn',{on:{"click":_vm.signOut}},[_vm._v("Sign Out")])],1):_vm._e()],1):_vm._e(),_c('v-main',{style:(_vm.pageStyles)},[_c('v-container',{staticStyle:{"height":"100%"}},[_c('nuxt')],1)],1),(!_vm.$vuetify.breakpoint.mobile)?_c('v-footer',{staticClass:"d-flex",attrs:{"absolute":!_vm.fixed,"app":""}},[(_vm.isAdmin && _vm.currentPlayer)?_c('MiniSongPlayer',{attrs:{"songPlayer":_vm.currentPlayer}}):_vm._e(),_c('span',{staticClass:"d-none d-sm-inline ml-auto"},[_vm._v("NUSIC © "+_vm._s(new Date().getFullYear()))])],1):_vm._e(),(_vm.isAdmin && _vm.$vuetify.breakpoint.mobile && _vm.currentPlayer)?_c('MiniSongPlayer',{attrs:{"songPlayer":_vm.currentPlayer}}):_vm._e(),(_vm.user.state.isSignedIn && _vm.$vuetify.breakpoint.mobile)?_c('v-bottom-navigation',{staticClass:"overflow-auto",attrs:{"app":"","color":"primary","shift":""},model:{value:(_vm.bottomNavigation),callback:function ($$v) {_vm.bottomNavigation=$$v},expression:"bottomNavigation"}},_vm._l((_vm.bottomNavigationItems),function(item,index){return _c('v-btn',{key:index,attrs:{"value":item.title,"to":item.to,"nuxt":""}},[_c('span',[_vm._v(_vm._s(item.title))]),_c('v-icon',[_vm._v(_vm._s(item.icon))])],1)}),1):_vm._e(),_c('RequireInteractionDialog'),_c('NotificationDialog'),_c('NewContentRefreshDialog')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }