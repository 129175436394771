import { Song, Section, StemKind } from '~/models/song'
import { SuperpoweredNode } from '~/models/superpowered'

export enum Mode {
  initial = 'initial',
  setup = 'setup',
  downloading = 'downloading',
  ready = 'ready',
  playing = 'playing',
  recording = 'recording',
}
export class State {
  // eslint-disable-next-line no-useless-constructor
  constructor(public mode = Mode.initial) {}
  // mode = Mode.initial
  get isInitial() {
    return this.mode === Mode.initial
  }

  get isSetup() {
    return this.mode === Mode.setup
  }

  get isDownloading() {
    return this.mode === Mode.downloading
  }

  get isReady() {
    return this.mode === Mode.ready
  }

  get isPlaying() {
    return this.mode === Mode.playing
  }

  get isRecording() {
    return this.mode === Mode.recording
  }

  get hasSetup() {
    return this.mode !== Mode.initial
  }

  get isLoaded() {
    return this.mode !== Mode.initial && this.mode !== Mode.setup
  }

  set(mode: Mode) {
    this.mode = mode
  }

  togglePlaying() {
    switch (this.mode) {
      case Mode.playing:
        this.mode = Mode.ready
        break
      case Mode.ready:
        this.mode = Mode.playing
        break
      default:
        throw new Error(`Unable to togglePlaying in mode ${this.mode}`)
    }
  }
}
// Create a type which represents only one of the above types
// but you aren't sure which it is yet.
export class Send {
  static get play() {
    return { play: true }
  }

  static get stop() {
    return { stop: true }
  }

  static section(
    bpm: number,
    startBeat: number,
    left: Float32Array,
    right: Float32Array
  ) {
    return { section: { bpm, startBeat, left, right } }
  }

  static sectionPlayer(player: SectionPlayer) {
    return {
      sectionPlayer: {
        bpm: player.song.bpm,
        startBeat: player.section.beatStart,
        stems: player.stems,
        stemVolume: player.stemVolume,
      },
    }
  }

  static stemVolume(stemVolume: StemVolume) {
    return {
      stemVolume,
    }
  }

  static pitchShift(pitchShift: number) {
    return {
      pitchShift,
    }
  }

  static timeStretch(timeStretching: number) {
    return { timeStretching }
  }
}
// eslint-disable-next-line prettier/prettier
export type ProcessorMessage =
  | { posFrames: number; beats: number; sixteenths: number }
  | { peakVolume: number }

/**
 * @deprecated Retained only until [timestretch.js]{@link ~/static/processors/timestretch.js} is updated
 */
export interface SectionData {
  startFrame: number
  endFrame: number
}

export enum PlayerMode {
  uninitialized = 'uninitialized',
  ready = 'ready',
  playing = 'playing',
}

export class StemVolume {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public bass: number = 1,
    public drums: number = 1,
    public melody: number = 1,
    public vocals: number = 1
  ) {}
}

export class SectionPlayer {
  private audioNode?: SuperpoweredNode
  currentBeat: number
  currentSixteenths = 1
  mode = PlayerMode.uninitialized
  pitchShift = 0
  stemVolume = new StemVolume()
  timeStretch = 1
  loopEnabled = true

  private notifyStopped: (() => void)[] = []
  private notifyLoopEnd: (() => void)[] = []

  addNotifyStoppedCallback(callback: () => void): void {
    this.notifyStopped.push(callback)
  }

  addNofityLoopEndCallback(callback: () => void): void {
    this.notifyLoopEnd.push(callback)
  }

  get duration() {
    // FIXME: Get correct sample rate
    return (this.stems.endFrame - this.stems.startFrame) / 44100
  }

  constructor(
    public song: Song,
    public section: Section,
    public sectionIndex: number,
    public stems: StemAudio
  ) {
    this.currentBeat = section.beatStart
  }

  get isReady() {
    return this.mode === PlayerMode.ready
  }

  get isPlaying() {
    return this.mode === PlayerMode.playing
  }

  play(stemVolume?: StemVolume) {
    this.sendMessageToAudioScope({ mode: PlayerMode.playing, stemVolume })
  }

  stop() {
    this.sendMessageToAudioScope({ mode: PlayerMode.ready })
  }

  togglePlay() {
    if (this.isPlaying) {
      this.stop()
    } else if (this.isReady) {
      this.play()
    } else {
      throw new Error(`sectionPlayer cannot togglePlay in mode ${this.mode}`)
    }
  }

  setAudioNode(audioNode: SuperpoweredNode) {
    this.audioNode = audioNode
    audioNode.sendMessageToAudioScope({
      sectionPlayer: {
        bpm: this.song.bpm,
        startBeat: this.section.beatStart,
        stems: this.stems,
        stemVolume: this.stemVolume,
      },
    })
  }

  onMessageFromAudioScope(message: object) {
    // console.log(`onMessageFromAudioScope(${JSON.stringify(message)}`)
    if ('stopped' in message) {
      this.mode = PlayerMode.ready
      this.notifyStopped.forEach((callback) => callback())
    }
    if ('loopEnd' in message) {
      this.notifyLoopEnd.forEach((callback) => callback())
    }
  }

  sendMessageToAudioScope(message: Partial<SectionPlayer>) {
    Object.assign(this, message)
    this.audioNode?.sendMessageToAudioScope(message)
  }
}

export class StemPlayer {
  sections: SectionPlayer[] = []
  // eslint-disable-next-line no-useless-constructor
  constructor(public song: Song) {}
}

export interface MixController {
  togglePlayers(players: SectionPlayer[]): void
  createAudioNode(
    onMessageCallback: (message: object) => void
  ): Promise<SuperpoweredNode>
  // Odd signature required due to onPadPlayerLoopEnd not being callable if defined as a ordinary member function
  onPadPlayerLoopEnd(mixer: MixController, padPlayer: PadPlayer): void
}

export class PadPlayer {
  get isPlaying(): boolean {
    return this.topPlayer.isPlaying && (this.leftPlayer?.isPlaying ?? false)
  }

  topVols: StemVolume
  leftVols = new StemVolume()
  // eslint-disable-next-line no-useless-constructor
  constructor(
    readonly mixer: MixController,
    readonly topPlayer: SectionPlayer,
    readonly leftPlayer?: SectionPlayer
  ) {
    this.topVols = { ...topPlayer.stemVolume }
    topPlayer.addNofityLoopEndCallback(() => {
      if (this.isPlaying) {
        // Odd signature required due to onPadPlayerLoopEnd not being callable if defined as a ordinary member function
        mixer.onPadPlayerLoopEnd(mixer, this)
      }
    })
    if (leftPlayer) {
      this.leftVols = { ...leftPlayer.stemVolume }
      topPlayer.addNotifyStoppedCallback(() => leftPlayer.stop())
    }
  }

  static copy(padPlayer: PadPlayer): PadPlayer {
    const newObject = Object.create(padPlayer)
    return Object.assign(newObject, padPlayer)
  }

  get sectionPlayers() {
    if (this.leftPlayer) return [this.topPlayer, this.leftPlayer]
    else return [this.topPlayer]
  }

  get coordinates() {
    const topCoord = this.topPlayer.sectionIndex
    const leftCoord = this.leftPlayer?.sectionIndex ?? -1
    return [topCoord, leftCoord]
  }

  play() {
    this.topPlayer.play(this.topVols)
    this.leftPlayer?.play(this.leftVols)
  }

  stop() {
    this.sectionPlayers.forEach((p) => p.stop())
  }

  togglePlay() {
    if (this.isPlaying) {
      this.stop()
    } else {
      this.play()
    }
  }
}
export interface AudioData {
  left: Float32Array
  right: Float32Array
}

export interface StemAudio {
  startFrame: number
  endFrame: number
  bass: AudioData
  drums: AudioData
  melody: AudioData
  vocals: AudioData
  // eslint-disable-next-line no-useless-constructor
}
export enum MixerMode {
  interactive = 'interactive',
  playing = 'playing',
  recording = 'recording',
}

export { StemKind }
