import firebase from '~/services/firebase.service'
type Referal = {
  referalLink: string
}
export class UserDocument {
  createdAt: firebase.firestore.Timestamp = firebase.firestore.Timestamp.now()
  createdBy: string = 'app.nusic.fm'
  roleIds: string[] = []
  email: string | null
  name: string | null
  photoUrl: string | null
  referal?: Referal
  signInMethods?: string
  updatedAt?: firebase.firestore.Timestamp
  invitedBy?: string | null
  googleCloudMessagingToken?: string | null

  constructor(user: firebase.User) {
    const { email, displayName, photoURL } = user
    this.email = email
    this.name = displayName
    this.photoUrl = photoURL
  }
}
