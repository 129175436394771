import { createModule, mutation, action } from 'vuex-class-component'

import firebase, {
  auth,
  GoogleAuthProvider,
  db,
} from '~/services/firebase.service'
import { devAuth, FacebookAuthProvider } from '~/services/firebase.dev.service'
import {
  FirestoreUserService,
  UserService,
} from '~/services/firestore.user.service'

const VuexModule = createModule({
  namespaced: 'user',
  strict: false,
  target: 'nuxt',
  enableLocalWatchers: true,
})

export class UserStore extends VuexModule {
  firebaseUser: firebase.User | null = null
  firebaseDevUser: firebase.User | null = null
  isSigningIn = false
  isSubscribedToProdAuthStateChange = false
  userService: UserService | null = null
  // Explicitly define a vuex getter using class getters.
  @action
  async getUserCredential(): Promise<firebase.auth.UserCredential | null> {
    const userCache = await window.caches.open('user-cache')
    const response = await userCache.match('https://google.com/userCredential')
    if (response != null) {
      const credential = (await response.json()) as firebase.auth.UserCredential
      return credential
    } else return null
  }

  @action async setUserCredential(credential: firebase.auth.UserCredential) {
    if (credential.user == null) return
    const userCache = await window.caches.open('user-cache')
    const jsonstring = JSON.stringify(credential, null, 2)
    let createdAt = 'unknown'
    try {
      createdAt = new Date(Date.now()).toISOString()
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`${err} createdAt is undefined`)
    }

    const songResponse = new Response(jsonstring, {
      // @ts-ignore
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': jsonstring.length,
        'Created-At': createdAt,
      },
    })
    await userCache.put(
      new Request('https://google.com/userCredential'),
      songResponse
    )
  }

  get isSignedIn() {
    return this.firebaseUser != null
  }

  get isSignedOut() {
    return this.firebaseUser == null
  }

  get email() {
    return this.firebaseUser?.email
  }

  get displayName() {
    return this.firebaseUser?.displayName
  }

  get photoURL() {
    return this.firebaseUser?.photoURL
  }

  @mutation subscribeToAuth() {
    if (auth.currentUser) {
      this.firebaseUser = auth.currentUser
    }
    if (this.isSubscribedToProdAuthStateChange === false) {
      auth.onAuthStateChanged(async (firebaseUser) => {
        this.firebaseUser = firebaseUser
        this.isSigningIn = false
        if (firebaseUser) {
          this.userService = new FirestoreUserService(db, auth)
          await this.userService.getOrCreateUserDocument(null)
        } else {
          this.userService = null
        }
      })
      this.isSubscribedToProdAuthStateChange = true
    }
  }

  @action async signIn() {
    await this.signInWithPopup()
  }

  @action async signInWithPopup() {
    this.isSigningIn = true
    const provider = GoogleAuthProvider
    provider.addScope('profile')
    provider.addScope('email')
    const credential = await auth.signInWithPopup(provider)
    await this.setUserCredential(credential)
  }

  @action async signInWithFacebook() {
    this.isSigningIn = true
    const provider = FacebookAuthProvider
    provider.addScope('user_birthday')
    provider.setCustomParameters({
      display: 'popup',
    })
    const credential = await auth.signInWithPopup(provider)
    await this.setUserCredential(credential)
  }

  @mutation signOut() {
    auth.signOut()
    devAuth.signOut()
  }

  @action async signInToFirebaseDev() {
    if (devAuth.currentUser != null) return
    const userCredential = await this.getUserCredential()
    if (userCredential == null || userCredential.credential == null) {
      // eslint-disable-next-line no-console
      this.signOut()
      return
    }
    // https://stackoverflow.com/questions/41651589/firebase-signinwithcredential-failed-first-argument-credential-must-be-a-vali
    const authCredential = userCredential.credential as firebase.auth.AuthCredential & {
      oauthIdToken: string | null | undefined
    }
    try {
      if (authCredential.oauthIdToken == null) {
        throw new Error('oauthIdToken is undefined')
      }
      const token = authCredential.oauthIdToken
      const credential = firebase.auth.GoogleAuthProvider.credential(token)
      await devAuth.signInWithCredential(credential)
      this.firebaseDevUser = devAuth.currentUser
      // eslint-disable-next-line no-console
      console.log(`👩‍🎤 Signed-in to nusic-mashups-dev`)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(
        `Error: Unable to sign-in to firebase nusic-mashups-dev: ${err}`
      )
      this.signOut()
    }
  }
}
