










import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  data() {
    return {
      isShowRefreshDialog: false,
    }
  },
  async mounted() {
    // @ts-ignore
    const workbox = await window.$workbox
    if (workbox) {
      workbox.addEventListener(
        'installed',
        (event: Event & { isUpdate: true }) => {
          // If we don't do this we'll be displaying the notification after the initial installation, which isn't perferred.
          if (event.isUpdate) {
            // whatever logic you want to use to notify the user that they need to refresh the page.
            this.isShowRefreshDialog = true
          }
        }
      )
    }
  },
  methods: {
    onClickAction(): void {
      window.location.reload()
    },
  },
})
