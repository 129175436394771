type seconds = number

function validateBeatsPerMinute(bpm: number) {
  if (bpm <= 0)
    throw new Error(`bpm is invalid: ${bpm} must be greater than zero)`)
}

function validateBeatNumber(beatNumber: number) {
  if (beatNumber <= 0)
    throw new Error(
      `beatNumber is invalid: ${beatNumber} must be greater than zero`
    )
}
function validateBeatOffset(offset: number) {
  if (offset < 0)
    throw new Error(
      `beatNumber is invalid: ${offset} must be greater or equal to zero`
    )
}

export function secondsPerBeat(bpm: number) {
  validateBeatsPerMinute(bpm)
  return 60 / bpm
}

export function beatsPerSecond(bpm: number) {
  validateBeatsPerMinute(bpm)
  return bpm / 60
}

export function beatNumberToSeconds(
  bpm: number,
  firstBeatOffset: seconds,
  beatNumber: number
) {
  validateBeatOffset(firstBeatOffset)
  validateBeatNumber(beatNumber)
  return firstBeatOffset + secondsPerBeat(bpm) * (beatNumber - 1)
}
/*
 * calculates the sample offset of the beat
 */
export function beatNumberToFramePosition(
  sampleRate: number,
  bpm: number,
  firstBeatOffset: seconds,
  beatNumber: number
) {
  return beatNumberToSeconds(bpm, firstBeatOffset, beatNumber) * sampleRate
}

export function secondsToBeats(
  bpm: number,
  firstBeatOffset: seconds,
  elapsedTime: seconds
) {
  return (elapsedTime - firstBeatOffset) * beatsPerSecond(bpm) + 1
}

export function beatsToTransportTime(beats: number) {
  const zeroBasedBeats = beats - 1
  const wholeBeats = Math.floor(zeroBasedBeats)
  const partBeat = zeroBasedBeats - wholeBeats
  const bars = `${Math.floor(wholeBeats / 4)}`.padStart(2, '0')
  const quarters = `${wholeBeats % 4}`.padStart(2, '0')
  const sixteenths = `${Math.floor(partBeat * 4)}`
  return `${bars}:${quarters}:${sixteenths}`
}

export function beatsToShortTransportTime(beats: number) {
  const zeroBasedBeats = beats - 1
  const wholeBeats = Math.floor(zeroBasedBeats)
  const bars = `${Math.floor(wholeBeats / 4)}`.padStart(2, '0')
  const beat = `${wholeBeats % 4}`.padStart(2, '0')
  return `${bars}:${beat}`
}

export function secondsToTransportTime(
  bpm: number,
  firstBeatOffset: seconds,
  elapsedTime: seconds
) {
  const beats = secondsToBeats(bpm, firstBeatOffset, elapsedTime)
  return beatsToTransportTime(beats)
}

export function secondsToShortTransportTime(
  bpm: number,
  firstBeatOffset: seconds,
  elapsedTime: seconds
) {
  const beats = secondsToBeats(bpm, firstBeatOffset, elapsedTime)
  return beatsToShortTransportTime(beats)
}

export const majorKeys = new Map([
  ['C', 0],
  ['C#', 1],
  ['Db', 1],
  ['D', 2],
  ['D#', 3],
  ['Eb', 3],
  ['E', 4],
  ['F', 5],
  ['F#', 6],
  ['Gb', 6],
  ['G', 7],
  ['G#', 8],
  ['Ab', 8],
  ['A', 9],
  ['A#', 10],
  ['Bb', 10],
  ['B', 11],
])

export const minorKeys = new Map([
  ['Am', 0],
  ['A#m', 1],
  ['Bbm', 1],
  ['Bm', 2],
  ['Cm', 3],
  ['C#m', 4],
  ['Dbm', 4],
  ['Dm', 5],
  ['D#m', 6],
  ['Ebm', 6],
  ['Em', 7],
  ['Fm', 8],
  ['F#m', 9],
  ['Gbm', 9],
  ['Gm', 10],
  ['G#m', 11],
  ['Abm', 11],
])

function keyToInteger(key: string): number {
  if (minorKeys.has(key)) return minorKeys.get(key)!
  if (majorKeys.has(key)) return majorKeys.get(key)!
  throw new Error(`Invalid musical key: ${key}`)
}

export function calculateKeyDifference(key1: string, key2: string): number {
  const difference = keyToInteger(key1) - keyToInteger(key2)
  if (Math.abs(difference) < 7) return difference
  else if (difference < 0) return difference + 12
  else return difference - 12
}

const keyStringMap = new Map([
  ['C', 'C major '],
  ['Cm', 'C minor'],
  ['C#', 'C# major '],
  ['C#m', 'C# minor'],
  ['Db', 'D♭ major '],
  ['Dbm', 'D♭ minor'],
  ['D', 'D major '],
  ['Dm', 'D minor'],
  ['D#', 'D# major '],
  ['D#m', 'D# minor'],
  ['Eb', 'E♭ major '],
  ['Ebm', 'E♭ minor'],
  ['E', 'E major  '],
  ['Em', 'E minor'],
  ['F', 'F major  '],
  ['Fm', 'F minor'],
  ['F#', 'F# major'],
  ['F#m', 'F# minor'],
  ['Gb', 'G♭ major'],
  ['Gbm', 'G♭ minor'],
  ['G', 'G major'],
  ['Gm', 'G minor'],
  ['G#', 'G# major'],
  ['G#m', 'G# minor'],
  ['Ab', 'A♭ major '],
  ['Abm', 'A♭ minor'],
  ['A', 'A major '],
  ['Am', 'A minor'],
  ['A#', 'A# major '],
  ['A#m', 'A# minor'],
  ['Bb', 'B♭ major '],
  ['Bbm', 'B♭ minor'],
  ['B', 'B major'],
  ['Bm', 'B minor '],
])

export function keytoString(key: string): string {
  if (keyStringMap.has(key)) return keyStringMap.get(key)!
  // eslint-disable-next-line no-console
  console.error(`Unknown key: ${key}`)
  return key
}
